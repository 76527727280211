import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { rootStore } from './stores/RootStore';
import RootStoreContext from './stores/RootStoreContext';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(

  <RootStoreContext.Provider value={rootStore}>
    <App />
  </RootStoreContext.Provider>

);