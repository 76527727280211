// src/components/Icon/Icon.tsx
import React from 'react';
import styles from './Icon.module.scss';

type ImgProps = {
    src: string;
    alt?: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

type SvgProps = {
    src: React.FC<React.SVGProps<SVGSVGElement>>;
    alt?: string;
    fill?: string;
} & React.SVGProps<SVGSVGElement>;

export type IconProps = ImgProps | SvgProps;

const Icon: React.FC<IconProps> = (props) => {
    if (typeof props.src === 'string') {
        // Если src — строка, считаем, что это URL изображения
        const { src, alt, ...rest } = props as ImgProps;
        return <img src={src} alt={alt} className={styles.icon} {...rest} />;
    } else {
        // Если src — SVG-компонент, выполняем сужение типа до SvgProps
        const { src: SvgIcon, alt, fill, ...rest } = props as SvgProps;
        return <SvgIcon fill={fill} aria-label={alt} {...rest} />;
    }
};

export default Icon;
