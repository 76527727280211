import { makeAutoObservable, runInAction, reaction } from "mobx";
import $api from "../http/api";
import { RootStore } from "./RootStore";
import { GoodDetail } from "./abstractions/GoodDetail";
import { bot_url_with_param } from "../http/urls";
import { waitForAuth } from "../utils/authWaiter";
export class GoodStore {
    selectedGood: number | null = null;
    goodData: GoodDetail | null = null;
    error: string | null = null;
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
    async setSelectedGood(id: number) {
        if (id) {
            runInAction(() => {
                this.selectedGood = id;
            });

            await this.getGoodData();
        }
    }

    async getGoodData(): Promise<void> {
        await waitForAuth(this.rootStore.authStore);
        try {
            if (this.selectedGood) {
                const response = await $api.get(`/click/${this.selectedGood}`, { addScope: true });
                if (response.status === 200) {
                    runInAction(() => {
                        this.goodData = response.data;
                    });
                }
            }
        } catch (error) {
            runInAction(() => {
                this.error = "Error fetching good data";
            });
        }
    }

    async getGoodDataByLink(link: string): Promise<void> {

        await waitForAuth(this.rootStore.authStore);
        console.log('getting data by link')
        // После авторизации выполняем запрос
        try {
            const response = await $api.get(`/resolveGoodLink/${link}`, { addScope: true });
            const goodId = response.data.id;
            runInAction(() => {
                this.selectedGood = goodId;
            });

            // Загружаем данные о товаре
            this.rootStore.navigation.navigate?.(`/good/${goodId}`);
            await this.getGoodData();
        } catch (error) {
            runInAction(() => {
                this.error = "Failed to fetch good by link";
            });
        }
    }

    shareGood(link: string, name: string): void {
        const urlWithParams = `${bot_url_with_param}${link}`;
        const shareText = `Смотри, что я нашел! ${name}`;
        const encodedShareText = encodeURIComponent(shareText);
        window.open(
            `https://t.me/share/url?url=${urlWithParams}&text=${encodedShareText}`
        );
    }
    clearGoodData() {
        runInAction(() => {
            this.selectedGood = null;
            this.goodData = null;
            this.error = null;
        });
    }
}