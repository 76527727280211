export const getItemAsync = (key: string): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        window.Telegram.WebApp.CloudStorage.getItem(key, (error: any, value: string | null) => {
            if (error) {
                console.error(`Ошибка получения ${key}:`, error);
                reject(error);
            } else {
                resolve(value);
            }
        });
    });
};

export const setItem = (key: string, value: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        window.Telegram.WebApp.CloudStorage.setItem(key, value, (result: any) => {
            if (result !== null) {
                console.error(`Ошибка при установке ${key}:`, result);
                reject(result);
            } else {
                resolve();
            }
        });
    });
};

export type CloudStorageResult = {
    setItem: (key: string, value: string) => Promise<void>;
    getItemAsync: (key: string) => Promise<string | null>;
};