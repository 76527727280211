// src/screens/Good/Good.tsx
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import classes from './Good.module.scss';
import Favorite from '../../components/Favorite/Favorite';
import ImageCard from '../../components/ImageCard/ImageCard';
import ShareButton from '../../components/ShareButton/ShareButton';
import BasketButton from '../../components/Basket/BasketButton/BasketButton';
import { useStores } from '../../stores/RootStoreContext';

const Good: React.FC = observer(() => {
  const { goodStore } = useStores();
 
  // Выбранный товар из goodStore
  const item = goodStore.goodData;

  // При монтировании, если товар не загружен, пытаемся его получить
  useEffect(() => {
    if (!item) {
      goodStore.getGoodData().catch(err => {
        console.error("Ошибка при получении данных товара", err);
      });
    }
  }, [item, goodStore]);

  return (
    <div className={`${classes.container}`}>
      {item ? (
        <div className={classes.goodWrapper}>
          <div className={classes.block1}>
            <ImageCard photo={item.photoUrls} full={true}/>
            <div className={classes.infoBlock}>
              <div className={classes.name}>{item.name}</div>
              <div className={classes.block1_2}>
                <div className={classes.price}>{item.price} ₽</div>
                <div className={classes.btnsBlock}>
                <ShareButton link={item.link} name={item.name} OnShare={goodStore.shareGood}/>
                <Favorite id={item.id} page={true}/>
                </div>
                
              </div>
            </div>
          </div>
          <div className={classes.colorSizeLine}>
            <div className={classes.coloSizeSelector}>
              <div className={classes.colorSizeValue}>
                {item.color ? item.color : '---'}
              </div>
              <div className={classes.colorSizeLabel}>
                Цвет
              </div>
            </div>
            <div className={classes.coloSizeSelector}>
              <div className={classes.colorSizeValue}>
                {item.size ? item.size : '---'}
              </div>
              <div className={classes.colorSizeLabel}>
                Размер
              </div>
            </div>
          </div>
          <div className={classes.busketLine}>
            <BasketButton itemDetail={item} />
          </div>
          <div className={classes.block3}>
            <div className={classes.infoText3}>Описание</div>
            <div
              className={classes.infoText4}
              dangerouslySetInnerHTML={{ __html: item.description || "" }}
            />
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
});

export default Good;
