// src/components/Card/Card.tsx
import React, { FC, useCallback } from 'react';
import { useStores } from '../../stores/RootStoreContext';
import Favorite from '../Favorite/Favorite';
import ImageCard from '../ImageCard/ImageCard';
import styles from './Card.module.scss';
import { useNavigate } from 'react-router-dom';

interface CardProps {
    appRef: React.RefObject<HTMLDivElement | null>;
    endIndex: number;
    id: number;
    name: string;
    photoUrl?: string;
    price?: number;
}

const truncateName = (name: string | undefined, maxLength: number): string => {
    if (!name) return '';
    if (name.length > maxLength) {
        return `${name.slice(0, maxLength - 3)}...`;
    }
    return name;
};

const Card: FC<CardProps> = ({ appRef, endIndex, id, name, photoUrl, price }) => {
    const { catalogStore, goodStore } = useStores();
    const navigate = useNavigate();
    const handleCardClick = useCallback(() => {
        // Сохраняем позицию экрана через store
        catalogStore.setEndIndex(endIndex);
        if (appRef.current) {
            catalogStore.setScrollTop(appRef.current.scrollTop);
        }
        navigate(`/good/${id}`);
    }, [catalogStore, appRef, endIndex, id, navigate]);

    return (
        <div className={styles.card} onClick={handleCardClick}>
            <ImageCard photo={photoUrl} />
            <Favorite id={id} />
            <div className={styles.info}>
                <div className={styles.block}>
                    <p className={styles.price}>{price} ₽</p>
                </div>
                <div className={styles.blockName}>
                    {truncateName(name, 38)}
                </div>
            </div>
        </div>
    );
};

export default Card;
