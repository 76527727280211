import { GoodCard } from "../abstractions/GoodCard";

export const goodsTestData: GoodCard[] = [
    {
        id: 1,
        article: "A001",
        categoryName: "Электроника",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer A",
        name: "Умный телефон",
        price: 1000.00,
    },
    {
        id: 2,
        article: "A002",
        categoryName: "Бытовая техника",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer B",
        name: "Стиральная машина",
        price: 2500.50,
    },
    {
        id: 3,
        article: "A003",
        categoryName: "Одежда",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer C",
        name: "Куртка",
        price: 150.75,
    },
    {
        id: 4,
        article: "A004",
        categoryName: "Спорт",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer D",
        name: "Футбольный мяч",
        price: 75.00,
    },
    {
        id: 5,
        article: "A001",
        categoryName: "Электроника",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer A",
        name: "Умный телефон",
        price: 1000.00,
    },
    {
        id: 6,
        article: "A002",
        categoryName: "Бытовая техника",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer B",
        name: "Стиральная машина",
        price: 2500.50,
    },
    {
        id: 7,
        article: "A003",
        categoryName: "Одежда",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer C",
        name: "Куртка",
        price: 150.75,
    },
    {
        id: 8,
        article: "A004",
        categoryName: "Спорт",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer D",
        name: "Футбольный мяч",
        price: 75.00,
    },
    {
        id: 9,
        article: "A001",
        categoryName: "Электроника",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer A",
        name: "Умный телефон",
        price: 1000.00,
    },
    {
        id: 10,
        article: "A002",
        categoryName: "Бытовая техника",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer B",
        name: "Стиральная машина",
        price: 2500.50,
    },
    {
        id: 11,
        article: "A003",
        categoryName: "Одежда",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer C",
        name: "Куртка",
        price: 150.75,
    },
    {
        id: 12,
        article: "A004",
        categoryName: "Спорт",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer D",
        name: "Футбольный мяч",
        price: 75.00,
    },
    {
        id: 13,
        article: "A001",
        categoryName: "Электроника",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer A",
        name: "Умный телефон",
        price: 1000.00,
    },
    {
        id: 14,
        article: "A002",
        categoryName: "Бытовая техника",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer B",
        name: "Стиральная машина",
        price: 2500.50,
    },
    {
        id: 15,
        article: "A003",
        categoryName: "Одежда",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer C",
        name: "Куртка",
        price: 150.75,
    },
    {
        id: 16,
        article: "A004",
        categoryName: "Спорт",
        photoUrl: "https://via.placeholder.com/150",
        producerName: "Producer D",
        name: "Футбольный мяч",
        price: 75.00,
    },
];
export const favoritesTestData: number[] = [1, 3];