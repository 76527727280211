// src/http/api.ts
import axios from 'axios';
import { api_addr, DEFAULT_SCOPE } from './urls';

// Импортируем наши функции работы с cloudStorage
import { getItemAsync as cloudGetItemAsync, setItem as cloudSetItem } from './cloudStorage';
const $api = axios.create({
    baseURL: api_addr,
});

// Вспомогательные функции, которые проверяют наличие miniApp-объектов
export async function getToken(key: string): Promise<string | null> {
    // В режиме разработки используем localStorage независимо от наличия CloudStorage
    if (process.env.NODE_ENV === "development") {
        return localStorage.getItem(key);
    }
    // В production пытаемся использовать cloudStorage, если он доступен
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.CloudStorage) {
        try {
            return await cloudGetItemAsync(key);
        } catch (error) {
            console.error(`Ошибка получения ${key} через cloudStorage`, error);
        }
    }
    // Если cloudStorage не доступен или произошла ошибка, используем localStorage
    return localStorage.getItem(key);
}

export async function setToken(key: string, value: string): Promise<void> {
    // В режиме разработки всегда используем localStorage
    if (process.env.NODE_ENV === "development") {
        localStorage.setItem(key, value);
        return;
    }
    // В production пытаемся использовать cloudStorage, если он доступен
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.CloudStorage) {
        try {
            await cloudSetItem(key, value);
            return;
        } catch (error) {
            console.error(`Ошибка установки ${key} через cloudStorage`, error);
        }
    }
    // Если cloudStorage не доступен или произошла ошибка, используем localStorage
    localStorage.setItem(key, value);
}

$api.interceptors.request.use(async (config) => {
    // Если установлен флаг addScope, добавляем scope в query-параметры
    if ((config as any).addScope) {
        config.params = config.params || {};
        // Если параметр scope еще не задан, добавляем его
        if (!config.params.scope) {
            config.params.scope = DEFAULT_SCOPE;
        }
        // Удаляем наш кастомный флаг, чтобы он не передавался серверу
        delete (config as any).addScope;
    }
    const token = await getToken('accessToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

$api.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = await getToken('refreshToken');
                const response = await axios.post(`${api_addr}/refresh`, {}, {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`
                    }
                });
                const { accessToken, refreshToken: newRefreshToken } = response.data;
                await setToken('accessToken', accessToken);
                await setToken('refreshToken', newRefreshToken);
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                return $api(originalRequest);
            } catch (refreshError) {
                console.error('Refresh token failed', refreshError);
                await setToken('accessToken', '');
                await setToken('refreshToken', '');
                throw refreshError;
            }
        }
        return Promise.reject(error);
    }
);

export default $api;
