import { makeAutoObservable, runInAction } from "mobx";
import { Order } from './abstractions/Order';
import $api from "../http/api";
import { waitForAuth } from "../utils/authWaiter";
import { RootStore } from "./RootStore";

export class OrderStore {
    orders: Order[] = [];
    error: string | null = null;
    success: boolean = false;
    loading: boolean = false;
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async fetchOrders(): Promise<void> {
        this.error = null;
        this.loading = true;
        await waitForAuth(this.rootStore.authStore);
        try {
            const response = await $api.get<Order[]>("/orders", { addScope: true });
            runInAction(() => {
                this.orders = response.data;
                this.success = true;
                this.loading = false;
            });
            console.log(response.data)
        } catch (error: any) {
            runInAction(() => {
                this.error = error.response?.data?.message || error.message || "Ошибка при получении заказов";
                this.success = false;
                this.loading = false;
            });
        }
    }
    addOrder(newOrders: Order[]): void {
        runInAction(() => {
            this.orders = [...this.orders, ...newOrders];
        });
    }
    async cancelOrder(clientOrderId: string): Promise<void> {
        try {
            await $api.delete(`/order/${clientOrderId}`, { addScope: true });
            runInAction(() => {
                this.orders = this.orders.filter(order => order.clientOrderId !== clientOrderId);
            });
        } catch (error: any) {
            runInAction(() => {
                this.error = error.response?.data?.message || error.message || "Ошибка при отмене заказа";
            });
        }
    }
}