// src/components/Filters/Filters.tsx
import React, { FC, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Filters.module.scss';
import { useStores } from '../../stores/RootStoreContext';
import Dropdown, {Option} from '../Dropdown/Dropdown';
const Filters: FC = observer(() => {
  const { catalogStore } = useStores();
  const modalRef = useRef<HTMLDivElement>(null);

  // Закрываем окно, если клик вне модального окна
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      catalogStore.toggleFilters();
    }
  };
  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const options = Array.from(e.target.selectedOptions).map(opt => opt.value);
    catalogStore.setSelectedCategories(options);
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const options = Array.from(e.target.selectedOptions).map(opt => opt.value);
    catalogStore.setSelectedColors(options);
  };

  const handleSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const options = Array.from(e.target.selectedOptions).map(opt => opt.value);
    catalogStore.setSelectedSizes(options);
  };
  const handleReset = () => {
    catalogStore.resetFilters();
  };

  const categoryOptions: Option[] = catalogStore.availableCategories.map(cat => ({
    value: cat,
    label: cat
  }));

  const colorOptions: Option[] = catalogStore.availableColors.map(color => ({
    value: color,
    label: color
  }));

  const sizeOptions: Option[] = catalogStore.availableSizes.map(size => ({
    value: size,
    label: size
  }));


  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal} ref={modalRef}>
        <button className={styles.closeButton} onClick={() => catalogStore.toggleFilters()}>
          &times;
        </button>
        <h2 className={styles.title}>Фильтры</h2>
        <div className={styles.filterGroup}>
          <label className={styles.label}>Категория</label>
          <Dropdown
            options={categoryOptions}
            selected={catalogStore.selectedCategories}
            onChange={(selected: string[]) => catalogStore.setSelectedCategories(selected)}
            placeholder="Выберите категорию"
          />
        </div>
        <div className={styles.filterGroup}>
          <label className={styles.label}>Цвет</label>
          <Dropdown
            options={colorOptions}
            selected={catalogStore.selectedColors}
            onChange={(selected: string[]) => catalogStore.setSelectedColors(selected)}
            placeholder="Выберите цвет"
          />
        </div>
        <div className={styles.filterGroup}>
          <label className={styles.label}>Размер</label>
          <Dropdown
            options={sizeOptions}
            selected={catalogStore.selectedSizes}
            onChange={(selected: string[]) => catalogStore.setSelectedSizes(selected)}
            placeholder="Выберите размер"
          />
        </div>
        <button className={styles.resetButton} onClick={handleReset}>
          Сбросить
        </button>
      </div>
    </div>
  );
});

export default Filters;
