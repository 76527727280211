import { makeAutoObservable, reaction, runInAction } from "mobx";
import $api from "../http/api";
import { GoodCard } from "./abstractions/GoodCard";
import { goodsTestData } from "./test/GoodCards";
import { RootStore } from "./RootStore";
import { waitForAuth } from "../utils/authWaiter";

export class CatalogStore {
    goods: GoodCard[] = [];
    filteredGoods: GoodCard[] = [];
    error: string | null = null;
    loading: boolean = false;
    startIndex: number = 0;
    endIndex: number = 12;
    scrollTop: number = 0;
    showScrollButton: boolean = false;
    isFiltersVisible: boolean = false;
    rootStore: RootStore; 

    availableCategories: string[] = [];
    selectedCategories: string[] = [];
    availableColors: string[] = [];
    selectedColors: string[] = [];
    availableSizes: string[] = [];
    selectedSizes: string[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
    async fetchCatalogData(): Promise<void> {
      this.loading = true;
        await waitForAuth(this.rootStore.authStore);
        try {
            const response = await $api.get("/goods", { addScope: true });
            if (response.status === 200) {
                const shuffledCards = this.shuffleArray<GoodCard>(response.data);
                const cardsWithOrderId = shuffledCards.map((card: GoodCard, index: number) => ({
                    ...card,
                    order_id: index + 1,
                }));
                runInAction(() => {
                    this.goods = cardsWithOrderId;
                    this.filteredGoods = cardsWithOrderId;
                    this.loading = false;
                    this.initializeFilterOptions();
                });
            }
        } catch (error) {
            runInAction(() => {
                this.goods = goodsTestData;
                this.filteredGoods = goodsTestData;
                this.initializeFilterOptions();
                this.error = "Не удалось загрузить данные с сервера. Используем тестовые данные.";

            });
        }
    }
    initializeFilterOptions() {
        // Для категории предполагаем, что categoryName всегда строка.
        this.availableCategories = Array.from(
          new Set(this.goods.map(card => card.categoryName).filter(Boolean))
        );
      
        // Для цвета: если card.color – массив, то возвращаем все элементы, иначе, если строка – массив с одним элементом.
        this.availableColors = Array.from(
          new Set(
            this.goods.flatMap(card => {
              if (Array.isArray(card.color)) {
                return card.color.filter(Boolean);
              } else if (card.color) {
                return [card.color];
              }
              return [];
            })
          )
        );
      
        // Для размера: аналогично, обрабатываем и строку, и массив.
        this.availableSizes = Array.from(
          new Set(
            this.goods.flatMap(card => {
              if (Array.isArray(card.size)) {
                return card.size.filter(Boolean);
              } else if (card.size) {
                return [card.size];
              }
              return [];
            })
          )
        );
      }
    // Метод для перемешивания массива товаров
    shuffleArray<T>(array: T[]): T[] {
        return array.sort(() => Math.random() - 0.5);
    }
    // Вычисляемое свойство, возвращающее видимые карточки
    get visibleCards(): GoodCard[] {
        const actualEndIndex = Math.min(this.endIndex, this.filteredGoods.length);
        return this.filteredGoods.slice(this.startIndex, actualEndIndex);
      }
    applyFilters() {
    if (
        this.selectedCategories.length === 0 &&
        this.selectedColors.length === 0 &&
        this.selectedSizes.length === 0
    ) {
        runInAction(() => {
        this.filteredGoods = this.goods;
        this.endIndex = 12;
        });
        return;
    }
    const filtered = this.goods.filter(card => {
        const matchCategory =
        this.selectedCategories.length === 0 ||
        this.selectedCategories.includes(card.categoryName);
        const matchColor =
        this.selectedColors.length === 0 ||
        (typeof card.color === 'string'
          ? this.selectedColors.includes(card.color)
          : Array.isArray(card.color)
          ? card.color.some(c => this.selectedColors.includes(c))
          : false);
        const matchSize =
          this.selectedSizes.length === 0 ||
          (typeof card.size === 'string'
            ? this.selectedSizes.includes(card.size)
            : Array.isArray(card.size)
            ? card.size.some(s => this.selectedSizes.includes(s))
            : false);
        return matchCategory && matchColor && matchSize;
    });
    runInAction(() => {
        this.filteredGoods = filtered;
        this.endIndex = 12;
    });
    }
        // Методы для обновления выбранных фильтров
  setSelectedCategories(categories: string[]) {
    this.selectedCategories = categories;
    this.applyFilters();
  }

  setSelectedColors(colors: string[]) {
    this.selectedColors = colors;
    this.applyFilters();
  }

  setSelectedSizes(sizes: string[]) {
    this.selectedSizes = sizes;
    this.applyFilters();
  }

  resetFilters() {
    this.selectedCategories = [];
    this.selectedColors = [];
    this.selectedSizes = [];
    this.applyFilters();
  }

    // Методы для обновления состояния пагинации и скролла
    setEndIndex(newIndex: number) {
        this.endIndex = Math.min(newIndex, this.goods.length);
    }

    setScrollTop(newScrollTop: number) {
        this.scrollTop = newScrollTop;
    }

    setShowScrollButton(visible: boolean) {
        this.showScrollButton = visible;
    }
    toggleFilters() {
        this.isFiltersVisible = !this.isFiltersVisible;
      }
}