import { makeAutoObservable, runInAction } from "mobx";
import $api from "../http/api";
import { CloudStorageResult } from "./abstractions/CloudStorageResult";
import { TelegramResult } from "./abstractions/TelegramResult";
import { api_addr, INITDATA } from "../http/urls";
import { getToken, setToken } from "../http/api"
export class AuthStore {
    accessToken: string | null = null;
    refreshToken: string | null = null;
    isAuthenticated: boolean = false;
    hasContact: boolean | null = null;
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async telegramAuth(
        useCloudStorageResult: CloudStorageResult,
        useTelegramResult: TelegramResult
    ): Promise<void> {
        try {
            // Инлайн аутентификации: отправляем initData на сервер
            var initData = window.Telegram?.WebApp?.initData;
            if (initData === '') {
                initData = INITDATA;
            }
            const response = await $api.post('/registerOrAuthenticate', initData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const { accessToken, refreshToken } = response.data;
            if (accessToken) {
                await setToken("accessToken", accessToken);
                await setToken("refreshToken", refreshToken);
                $api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

                runInAction(() => {
                    this.accessToken = accessToken;
                    this.refreshToken = refreshToken;
                    this.isAuthenticated = true;
                });

                // Проверяем верификацию пользователя
                await this.checkVerification();
            }
        } catch (error) {
            console.log(error)
            runInAction(() => {
                this.error = "Authentication error";
            });
        }
    }
    async checkVerification(): Promise<void> {
        try {
            const response = await $api.get("/check-verification");
            const data = response.data;

            if (data?.isVerified) {
                await setToken("contact", "true");
                runInAction(() => {
                    this.hasContact = true;
                });
            } else {
                await setToken("contact", "false");
                runInAction(() => {
                    this.hasContact = false;
                });
            }
        } catch (error) {
            console.error("Error checking verification", error);
        }
    }
    async requestUserContact(
        cloudStorage: CloudStorageResult,
        telegramResult: TelegramResult
    ): Promise<void> {
        const { setItem } = cloudStorage;
        const { webApp } = telegramResult;
        try {
            // Отдельный запрос контакта
            webApp.requestContact(async (success: boolean, result: any) => {
                if (success) {
                    await setItem("contact", "true");
                    // Здесь можно дополнительно вызвать верификацию на сервере, если требуется
                    runInAction(() => {
                        this.hasContact = true;
                    });
                } else {
                    await setItem("contact", "false");
                    webApp.showPopup({
                        message:
                            "Вам необходимо будет связаться самостоятельно с администратором для обработки вашего заказа.",
                    });
                    runInAction(() => {
                        this.hasContact = false;
                    });
                }
            });
        } catch (error) {
            runInAction(() => {
                this.error = "Contact request denied";
            });
        }
    }
    async logout(): Promise<void> {
        await setToken("accessToken", "");
        await setToken("refreshToken", "");
        await setToken("contact", "false");

        runInAction(() => {
            this.accessToken = null;
            this.refreshToken = null;
            this.isAuthenticated = false;
            this.hasContact = null;
        });

        delete $api.defaults.headers.common["Authorization"];
    }
}
