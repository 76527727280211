// src/screens/GoodCardScreen/GoodCardScreen.tsx
import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styles from './GoodCardScreen.module.scss';
import Good from '../../components/Good/Good';
import { useStores } from '../../stores/RootStoreContext';
const GoodCardScreen: React.FC = observer(() => {
    const { id } = useParams<{ id: string }>();
    console.log(id)
    const { goodStore } = useStores();
    useEffect(() => {
        const init = async (id: string | undefined) =>{
            if (id) {
                // Преобразуем id в число и загружаем товар
               await goodStore.setSelectedGood(Number(id));
              }
        }

        init(id);
        return () => {
            goodStore.clearGoodData();
          };
      }, []);
    return (
    <div className={styles.container}>
        <Good/>
    </div>);
});

export default GoodCardScreen;
