// src/components/ShareButton/ShareButton.tsx
import React, { FC } from 'react';
import classes from './ShareButton.module.scss';

interface ShareButtonProps {
  link: string;
  name: string;
  OnShare: (link: string, name: string) => void;
}

const ShareButton: FC<ShareButtonProps> = ({ link, name, OnShare }) => {
  const handleShare = () => {
    OnShare(link, name);
  };

  return (
    <div onClick={handleShare}>
      <svg
        className={classes.btn}
        xmlns="http://www.w3.org/2000/svg"
        width="4vh"
        height="4vh"
        viewBox="0 0 24 24"
        fill="none"
        stroke="var(--like-color)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="18" cy="5" r="3" />
        <circle cx="6" cy="12" r="3" />
        <circle cx="18" cy="19" r="3" />
        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
      </svg>
    </div>
  );
};

export default ShareButton;
