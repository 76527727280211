// src/App.tsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from './stores/RootStoreContext';
import NavigationHandler from './components/NavigationHandler/NavigationHandler';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import RefScreen from './screens/RefScreen/RefScreen';
import CatalogScreen from './screens/CatalogScreen/CatalogScreen';
import GoodCardScreen from './screens/GoodCardScreen/GoodCardScreen';
import FavoriteScreen from './screens/FavoriteScreen/FavoriteScreen';
import BasketScreen from './screens/BasketScreen/BasketScreen';
import OrdersScreen from './screens/OrdersScreen/OrdersScreen';
import Menu from './components/Menu/Menu';
import * as cloudStorage from './http/cloudStorage';
import './App.css'
const AppContent = observer(() => {
  const { authStore, appStore, catalogStore, favoritesStore } = useStores();
  const location = useLocation();
  useEffect(() => {
    const initApp = async () => {
      try {
        const telegramResult = {
          webApp: window.Telegram.WebApp,
          user: window.Telegram.WebApp.initDataUnsafe?.user,
        };
        await authStore.telegramAuth(cloudStorage, telegramResult);
        await appStore.handleStartParam();
        await favoritesStore.fetchFavorites();
      } catch (error) {
        console.error("Ошибка при инициализации приложения", error);
      }
    };
    initApp();
    // Если требуется вызвать статистику, можно добавить вызов appClick через соответствующий store.
  }, []);
  useEffect(() => {
    // Пример логики управления видимостью меню в зависимости от маршрута
    const pathsWithoutMenu = [
      '/good/',]; // Например, на экране карточки товара можно скрыть меню
    const isMenuVisible = !pathsWithoutMenu.includes(location.pathname);
    appStore.setMenuVisibility(isMenuVisible);
  }, [location]);

  return (
    <div className="App">
      <div className={`content ${!appStore.isMenuVisible ? 'full' : ''}`}>
        <Routes>
          <Route path="/" element={<CatalogScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/ref" element={<RefScreen />} />
          <Route path="/good/:id" element={<GoodCardScreen />} />
          <Route path="/favorite" element={<FavoriteScreen />} />
          <Route path="/basket" element={<BasketScreen />} />
          <Route path="/orders" element={<OrdersScreen />} />
        </Routes>
      </div>
      {appStore.isMenuVisible && <Menu />}
    </div>
  );
});

const App: React.FC = () => (
  <Router>
    <NavigationHandler />
    <AppContent />
  </Router>
);

export default App;
