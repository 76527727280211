// src/components/Dropdown/Dropdown.tsx
import React, { FC, useState, useRef, useEffect } from 'react';
import styles from './Dropdown.module.scss';

export interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  options: Option[];
  selected: string[];
  onChange: (selected: string[]) => void;
  placeholder?: string;
}

const Dropdown: FC<DropdownProps> = ({
  options,
  selected,
  onChange,
  placeholder = "Выберите...",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Закрываем выпадающий список при клике вне его
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOption = (optionValue: string) => {
    if (selected.includes(optionValue)) {
      onChange(selected.filter(val => val !== optionValue));
    } else {
      onChange([...selected, optionValue]);
    }
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div className={styles.header} onClick={() => setIsOpen(prev => !prev)}>
        <span className={styles.headerText}>
          {selected.length > 0 
            ? options.filter(opt => selected.includes(opt.value)).map(opt => opt.label).join(", ") 
            : placeholder}
        </span>
        <span className={styles.arrow}>{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <div className={styles.options}>
          {options.map(option => (
            <div
              key={option.value}
              className={styles.option}
              onClick={() => toggleOption(option.value)}
            >
              <span className={styles.optionLabel}>{option.label}</span>
              {selected.includes(option.value) && (
                <span className={styles.marker}>✓</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
