// src/components/Success/Success.tsx
import React, { FC, useEffect } from "react";
import styles from "./Success.module.scss";

interface SuccessProps {
  message: string;
  duration?: number; // время в мс, по умолчанию 3000
  onClear: () => void;
}

const Success: FC<SuccessProps> = ({ message, duration = 3000, onClear }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClear();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onClear]);

  return (
    <div className={styles.overlay}>
      <div className={styles.successContainer}>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
};

export default Success;
