// src/components/ImageCard/ImageCard.tsx
import React, { FC, useEffect, useState, useRef } from 'react';
import styles from './ImageCard.module.scss';
import imagePlaceholder from '../../images/placeholder.svg';
import { ReactComponent as PlayIcon } from '../../images/play.svg';
interface ImageCardProps {
  photo?: string | string[]; // может быть один URL или массив URL-ов
  full?: boolean;
}
type MediaType = 'image' | 'video';

interface MediaItem {
  url: string;
  type: MediaType;
}

const isVideoUrl = (url: string): boolean => {
  return /\.(mp4|webm)$/i.test(url);
};
const ImageCard: FC<ImageCardProps> = ({ photo, full = false }) => {
  const mediaItems: MediaItem[] = Array.isArray(photo)
    ? photo
      .filter((p): p is string => Boolean(p))
      .map(url => ({ url, type: isVideoUrl(url) ? 'video' : 'image' }))
    : photo
      ? [{ url: photo, type: isVideoUrl(photo) ? 'video' : 'image' }]
      : [];
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentMedia, setCurrentMedia] = useState<MediaItem | null>(
    mediaItems.length > 0 ? mediaItems[0] : { url: imagePlaceholder, type: 'image' }
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Рефы для определения свайпа
  const touchStartX = useRef<number | null>(null);
  const touchEndX = useRef<number | null>(null);

  const maxRetries = 3;
  const retryDelay = 1000;

  const loadImage = (src: string, attempt: number = 1) => {
    setIsLoading(true);
    const img = new Image();
    img.src = src;

    // Если изображение уже загружено (кэш)
    if (img.complete) {
      setCurrentMedia({ url: src, type: 'image' });
      setIsLoading(false);
    } else {
      img.onload = () => {
        setCurrentMedia({ url: src, type: 'image' });
        setIsLoading(false);
      };
      img.onerror = () => {
        if (attempt < maxRetries) {
          setTimeout(() => loadImage(src, attempt + 1), retryDelay);
        } else {
          setIsLoading(false);
        }
      };
    }
  };
  // Функция загрузки медиа: если это изображение – используем loadImage, если видео – просто устанавливаем.
  const loadMedia = (media: MediaItem) => {
    if (media.type === 'image') {
      loadImage(media.url);
    } else {
      // Для видео можно попытаться загрузить метаданные, но здесь просто устанавливаем текущее видео.
      setCurrentMedia(media);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (mediaItems.length > 0) {
      loadMedia(mediaItems[currentIndex]);
    } else {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    if (mediaItems.length > 0) {
      loadMedia(mediaItems[currentIndex]);
    }
  }, [currentIndex]);
  // Предзагрузка следующего изображения (если это изображение)
  useEffect(() => {
    if (mediaItems.length > 0 && currentIndex < mediaItems.length - 1) {
      const next = mediaItems[currentIndex + 1];
      if (next.type === 'image') {
        const img = new Image();
        img.src = next.url;
      }
    }
  }, [mediaItems, currentIndex]);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current !== null && touchEndX.current !== null) {
      const deltaX = touchStartX.current - touchEndX.current;
      const threshold = 50; // порог для свайпа
      if (deltaX > threshold && currentIndex < mediaItems.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else if (deltaX < -threshold && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
    touchStartX.current = null;
    touchEndX.current = null;
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.imageBox} ${!full ? styles.imageBoxFull : ''}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {isLoading ? (
          <img className={styles.image} src={imagePlaceholder} alt="placeholder" />
        ) : currentMedia ? (
          currentMedia.type === 'image' ? (
            <img className={styles.image} src={currentMedia.url} alt="media" />
          ) : (
            <video
              className={styles.image}
              src={currentMedia.url}
              muted
              autoPlay
              loop
              playsInline
            />
          )
        ) : (
          <img className={styles.image} src={imagePlaceholder} alt="placeholder" />
        )}
      </div>
      {full && mediaItems.length > 1 && (
        <div className={styles.thumbnails}>
          {mediaItems.map((media, index) => (
            <div
              key={index}
              className={styles.thumbnailWrapper}
              onClick={() => setCurrentIndex(index)}
            >
              <img
                className={`${styles.thumbnail} ${index === currentIndex ? styles.activeThumbnail : ''}`}
                src={media.url}
                alt={`${index}`}
              />
              {media.type === 'video' && (
                <div className={styles.playOverlay}>
                  <PlayIcon className={styles.playIcon} />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageCard;
