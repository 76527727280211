import { reaction } from "mobx";
import { AuthStore } from "../stores/AuthStore"; // или путь к вашему authStore

export async function waitForAuth(authStore: AuthStore): Promise<void> {
  if (authStore.isAuthenticated) return;
  await new Promise<void>((resolve) => {
    const disposer = reaction(
      () => authStore.isAuthenticated,
      (isAuthenticated) => {
        if (isAuthenticated) {
          disposer();
          resolve();
        }
      }
    );
  });
}
