// src/components/Favorite/Favorite.tsx
import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import classes from './Favorite.module.scss';
import { useStores } from '../../stores/RootStoreContext';

interface FavoriteProps {
    id: number;
    page?: boolean
}

const Favorite: FC<FavoriteProps> = observer(({ id, page = false }) => {
    const { favoritesStore } = useStores();
    const isFavorite = favoritesStore.favorites.includes(id);

    const pressLike = async () => {
        console.log(`like pressed ${id}`)
        console.log(isFavorite)
        await favoritesStore.updateFavorite(id, isFavorite);
    };

    const handleClick = (e: React.MouseEvent<SVGSVGElement>) => {
        e.stopPropagation();
        pressLike();
    };

    return (
        <>
            {isFavorite ? (
                <svg
                    className={page ? classes.likePage : classes.like}
                    onClick={handleClick}
                    width="4vh"
                    height="4vh"
                    viewBox="0 0 26 24"
                    fill="var(--like-color)"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 .587l3.668 7.425L24 9.432l-6 5.855 1.418 8.283L12 18.567 4.582 23.57 6 15.287 0 9.432l8.332-1.42L12 .587z" />
                </svg>
            ) : (
                <svg
                    className={page ? classes.likePage : classes.like}
                    onClick={handleClick}
                    width="4vh"
                    height="4vh"
                    viewBox="0 0 26 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12 .587l3.668 7.425L24 9.432l-6 5.855 1.418 8.283L12 18.567 4.582 23.57 6 15.287 0 9.432l8.332-1.42L12 .587zm0 1.933l-3.012 6.086-6.706.974 4.855 4.737L6.314 21.4l5.686-2.991 5.686 2.991-1.299-7.083 4.855-4.737-6.706-.974L12 2.52z" fill="var(--like-color)" />
                </svg>
            )}
        </>
    );
});

export default Favorite;
