import { AppStore } from "./AppStore";
import { AuthStore } from "./AuthStore";
import { BasketStore } from "./BasketStore";
import { CatalogStore } from "./CatalogStore";
import { FavoritesStore } from "./FavoritesStore";
import { GoodStore } from "./GoodStore";
import { OrderStore } from "./OrderStore";
import { NavigateFunction } from "react-router-dom";

export class RootStore {
    authStore: AuthStore;
    appStore: AppStore;
    orderStore: OrderStore;
    catalogStore: CatalogStore;
    favoritesStore: FavoritesStore;
    goodStore: GoodStore;
    basketStore: BasketStore;
    navigation: {
        navigate: NavigateFunction | null;
    } = {
            navigate: null,
        };
    constructor() {
        this.authStore = new AuthStore();
        this.appStore = new AppStore(this);
        this.orderStore = new OrderStore(this);
        this.catalogStore = new CatalogStore(this);
        this.favoritesStore = new FavoritesStore(this);
        this.basketStore = new BasketStore(this);
        this.goodStore = new GoodStore(this);

    }
}

export const rootStore = new RootStore();