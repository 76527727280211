// src/components/BasketItem/BasketItem.tsx
import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { BasketItem as BasketItemType } from '../../../stores/abstractions/BasketItem';
import { useStores } from '../../../stores/RootStoreContext';
import styles from './BasketItem.module.scss';
import ImageCard from '../../ImageCard/ImageCard';
import { useNavigate } from 'react-router-dom';

interface BasketItemProps {
  item: BasketItemType;
}

const BasketItem: FC<BasketItemProps> = observer(({ item }) => {
  const { basketStore } = useStores();
  const navigate = useNavigate();
  const handleDecrease = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (item.quantity > 1) {
      basketStore.updateBasketItem(item.id, item.quantity - 1);
    } else {
      basketStore.removeFromBasket(item.id);
    }
  };

  const handleIncrease = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    basketStore.updateBasketItem(item.id, item.quantity + 1);
  };
  const handleClick = () => {
    navigate(`/good/${item.id}`);
  }
  // Вычисляем общую сумму для позиции
  const totalOriginal = item.price * item.quantity;
  const hasDiscount = item.discountPrice !== undefined && item.discountPrice < item.price;
  const totalDiscounted = hasDiscount ? item.discountPrice! * item.quantity : totalOriginal;

  return (
    <div className={styles.item} onClick={handleClick}>
        <div className={styles.photoblock }>
        <ImageCard photo={item.photoUrl}/>
        </div>
        
      <div className={styles.details}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.digitBlock}>
            <div className={styles.quantityControls}>
            <button onClick={handleDecrease} className={styles.button}>-</button>
            <span className={styles.quantity}>{item.quantity}</span>
            <button onClick={handleIncrease} className={styles.button}>+</button>
            </div>
            <div className={styles.priceSection}>
            {hasDiscount ? (
            <>
                <div className={styles.originalPrice}>
                <s>{totalOriginal.toFixed(2)} ₽</s>
                </div>
                <div className={styles.discountedPrice}>
                {totalDiscounted.toFixed(2)} ₽
                </div>
            </>
            ) : (
            <div className={styles.price}>{totalOriginal.toFixed(2)} ₽</div>
            )}
            </div>
        </div>
      </div>
    
    </div>
  );
});

export default BasketItem;
