// src/screens/BasketScreen/BasketScreen.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores/RootStoreContext';
import BasketItem from '../../components/Basket/BasketItem/BasketItem';
import styles from './BasketScreen.module.scss';
import Load from '../../components/Load/Load';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { runInAction } from 'mobx';
import Success from '../../components/Success/Success';
import { useNavigate } from 'react-router-dom';

const BasketScreen: React.FC = observer(() => {
  const { basketStore } = useStores();
  const navigate = useNavigate();
  const handleSubmitOrder = async () => {
    await basketStore.submitOrder();
  };

  return (
    <div className={styles.container}>
      {basketStore.items.length === 0 ? (
        <p className={styles.empty}>Ваша корзина пуста.</p>
      ) : (
        <>
            <div className={styles.upperBlock}>
                <h1 className={styles.title}>Корзина</h1>
                <div className={styles.items}>
                    {basketStore.items.map(item => (
                    <BasketItem key={item.id} item={item} />
                    ))}
                </div>
            </div>
            <button className={styles.submitButton} onClick={handleSubmitOrder}>
                Оформить заказ
            </button>
        </>
      )}
      {basketStore.loading && <Load message='Оформляем'/>}
      {basketStore.error && (
        <ErrorMessage 
            message={basketStore.error} 
            onClear={() => runInAction(() => { basketStore.error = null; })}
        />
    )}
    {basketStore.successMessage && (
        <Success 
          message={basketStore.successMessage} 
          onClear={() => {
            runInAction(() => { 
              basketStore.successMessage = "";
            });
            navigate('/orders');
          }
          }
          duration={3000}
        />
      )}
    </div>
  );
});

export default BasketScreen;
