import { makeAutoObservable, reaction, runInAction } from "mobx";
import $api from "../http/api";
import { RootStore } from "./RootStore";
import { waitForAuth } from "../utils/authWaiter";

export class FavoritesStore {
    favorites: number[] = [];
    error: string | null = null;
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
    async updateFavorite(goodId: number, isFavorite: boolean): Promise<void> {
        await waitForAuth(this.rootStore.authStore);
        // Сохраняем текущее состояние избранного
        const prevFavorites = [...this.favorites];
        console.log(`${goodId} ${isFavorite}`)
        // Оптимистичное обновление: сразу изменяем состояние для мгновенного отклика UI
        runInAction(() => {
            if (!isFavorite) {
                if (!this.favorites.includes(goodId)) {
                    this.favorites.push(goodId);
                }
            } else {
                this.favorites = this.favorites.filter((id) => id !== goodId);
            }
        });

        try {
            const url = `/favorites`;
            if (!isFavorite) {
                await $api.post(url, { goodId }, { addScope: true });
            } else {
                await $api.delete(url, { data: { goodId }, addScope: true });
            }
        } catch (error) {
            runInAction(() => {
                this.error = "Ошибка при выполнении запроса";
                // Если не в режиме разработки, откатываем изменения
                if (process.env.NODE_ENV !== "development") {
                    this.favorites = prevFavorites;
                }
            });
        }
    }

    async fetchFavorites(): Promise<void> {
        await waitForAuth(this.rootStore.authStore);
        try {
            const response = await $api.get("/favorites", { addScope: true });
            runInAction(() => {
                this.favorites = response.data;
            });
        } catch (error) {
            runInAction(() => {
                this.error = "Ошибка при загрузке избранного";
            });
        }
    }
}