// src/components/Menu/Menu.tsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStores } from '../../stores/RootStoreContext';

import Icon from '../Icon/Icon';
import styles from './Menu.module.scss';

import helmetGif from '../../images/gif/helmet.gif';
import profileGif from '../../images/gif/profile.gif';
import basketGif from '../../images/gif/basket.gif';
import ordersGif from '../../images/gif/orders.gif';
import filterGif from '../../images/gif/filter.gif';

interface MenuItem {
    id: string;
    label: string;
    path: string;
    IconComponent: React.FC<React.SVGProps<SVGSVGElement>> | string;
}

const menuItems: MenuItem[] = [
    {
        id: 'catalog',
        label: 'Каталог',
        path: '/',
        IconComponent: helmetGif,
    },
    {
        id: 'requests',
        label: 'Заказы',
        path: '/orders',
        IconComponent: ordersGif,
    },
    {
        id: 'refs',
        label: 'Корзина',
        path: '/basket',
        IconComponent: basketGif,
    },
    {
        id: 'profile',
        label: 'Профиль',
        path: '/profile',
        IconComponent: profileGif,
    }
];


const defaultFill = "var(--menu-icon-color)";
const activeFill = "var(--menu-active-icon-color)";

const Menu: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { catalogStore } = useStores();
    const [activeMenuItem, setActiveMenuItem] = useState<string>(
        menuItems.find(item => item.path === location.pathname)?.id || menuItems[0].id
    );

    useEffect(() => {
        const current = menuItems.find(item => item.path === location.pathname);
        if (current) {
            setActiveMenuItem(current.id);
        }
    }, [location]);

    const handleNavigation = (item: MenuItem) => {
        if (item.id === 'catalog' && location.pathname === '/') {
            console.log('here')
            catalogStore.toggleFilters();
        } else {
            navigate(item.path);
        }
    };

    return (
        <div className={styles.menu}>
            {menuItems.map(item => {
                const isActive = activeMenuItem === item.id;
                let iconSrc: string | React.FC<React.SVGProps<SVGSVGElement>> = item.IconComponent;
                let label = item.label;
                if (item.id === 'catalog' && location.pathname === '/') {
                    iconSrc = filterGif;
                    label = 'Фильтры';
                }
                return (
                    <div
                        key={item.id}
                        className={`${styles.menuItem} ${isActive ? styles.menuItemActive : ''}`}
                        onClick={() => {
                            setActiveMenuItem(item.id);
                            handleNavigation(item);
                        }}
                    >
                        {typeof iconSrc === 'string' ? (
                            <Icon src={iconSrc} alt={item.label} />
                        ) : (
                            <Icon
                                src={iconSrc}
                                alt={item.label}
                                fill={isActive ? activeFill : defaultFill}
                            />
                        )}
                        <span className={styles.menuLabel}>{label}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default Menu;
