// src/components/BasketBtn/BasketBtn.tsx
import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores/RootStoreContext';
import classes from './BasketButton.module.scss';
import { GoodDetail } from '../../../stores/abstractions/GoodDetail';
import { BasketItem } from '../../../stores/abstractions/BasketItem';

interface BasketBtnProps {
    itemDetail: GoodDetail;
}
const convertGoodDetailToBasketItem = (good: GoodDetail): BasketItem => ({
    id: good.id,
    photoUrl:  Array.isArray(good.photoUrls) ? good.photoUrls[0] : good.photoUrls,
    name: good.name,
    price: good.price,
    quantity: 1,
    link: good.link,
  });

const BasketButton: FC<BasketBtnProps> = observer(({ itemDetail }) => {
  const { basketStore } = useStores();
  const item = convertGoodDetailToBasketItem(itemDetail);
  const basketItem = basketStore.items.find(i => i.id === item.id);

  const handleAddToBasket = () => {
    basketStore.addToBasket(item);
  };

  const handleRemoveFromBasket = () => {
    if (basketItem && basketItem.quantity > 1) {
      basketStore.updateBasketItem(item.id, basketItem.quantity - 1);
    } else {
      basketStore.removeFromBasket(item.id);
    }
  };

  const handleIncreaseQuantity = () => {
    if (basketItem) {
      basketStore.updateBasketItem(item.id, basketItem.quantity + 1);
    }
  };

  return (
    <div className={classes.container}>
      {!basketItem ? (
        <button className={classes.addToBasket} onClick={handleAddToBasket}>
          В корзину
        </button>
      ) : (
        <div className={classes.quantityControl}>
          <button className={classes.decrease} onClick={handleRemoveFromBasket}>
            -
          </button>
          <span className={classes.quantity}>{basketItem.quantity}</span>
          <button className={classes.increase} onClick={handleIncreaseQuantity}>
            +
          </button>
        </div>
      )}
    </div>
  );
});

export default BasketButton;
