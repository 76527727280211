import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores/RootStoreContext";

const NavigationHandler = () => {
    const { appStore } = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        appStore.rootStore.navigation.navigate = navigate;
    }, [navigate, appStore.rootStore]);

    return null;
};

export default NavigationHandler;