import React, { FC } from "react";
import styles from "./Load.module.scss";

interface LoadProps {
  message: string;
}

const Load: FC<LoadProps> = ({ message }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
};

export default Load;
