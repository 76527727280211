// src/stores/BasketStore.ts
import { makeAutoObservable, reaction, runInAction } from "mobx";
import $api from "../http/api";
import { bot_url_with_param, DEFAULT_SCOPE, manager, USER_ID } from "../http/urls";
import { getItemAsync } from "../http/cloudStorage";
import { BasketItem } from "./abstractions/BasketItem";
import { RootStore } from "./RootStore";
import { waitForAuth } from "../utils/authWaiter";
import { OrderResponse } from "./abstractions/Order";

export class BasketStore {
  items: BasketItem[] = [];
  showBasket: boolean = false;
  loading: boolean = false;
  error: string | null = null;
  successMessage: string = "";
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }
  addToBasket(item: Omit<BasketItem, "quantity">) {
    const existing = this.items.find(i => i.id === item.id);
    if (!existing) {
      this.items.push({ ...item, quantity: 1 });
    }
  }

  removeFromBasket(itemId: number) {
    this.items = this.items.filter(i => i.id !== itemId);
  }

  updateBasketItem(itemId: number, quantity: number) {
    this.items = this.items.map(i =>
      i.id === itemId ? { ...i, quantity } : i
    );
  }

  setShowBasket(visible: boolean) {
    this.showBasket = visible;
  }

  clearBasket() {
    this.items = [];
  }

  async submitOrder(): Promise<void> {
    await waitForAuth(this.rootStore.authStore);
    const webApp = window.Telegram?.WebApp;
    if (!webApp) {
      console.error("Telegram WebApp не доступен");
      return;
    }
    
    this.loading = true;
    this.error = null;
    this.successMessage = "";

    const clientId = process.env.NODE_ENV === "development"
    ? USER_ID
    : webApp.initDataUnsafe?.user?.id;
    
    const orderPositions = this.items.map(item => ({
      GoodId: item.id,
      Amount: item.quantity,
      Price: item.discountPrice && item.discountPrice < item.price ? item.discountPrice : item.price,
    }));
    
    const orderData = {
      ClientId: clientId,
      PaymentMethod: "наличиные",
      PaymentStatus: "неоплачено",
      ClientOrderId: "",
      OrderPositions: orderPositions,
    };
    

    const confirmOrder = async () => {
      
      try {
        const response = await $api.post<OrderResponse>('/order', orderData, { addScope: true });
        runInAction(() => {
          this.loading = false;
          this.successMessage = response.data.message;
        });
        this.clearBasket();
        
        /*webApp.showPopup({
          message: 'Заказ успешно создан!',
        });
        const contact = await getItemAsync('contact');
        if (!contact) {
          let orderList = ' -------------------------------------------- ';
          this.items.forEach(item => {
            orderList += `${item.name} - ${item.quantity} шт. - ${item.price} руб. - ${bot_url_with_param}${item.link} --------------------------------------------  `;
          });
          const messageTemplate = `Id: ${clientId?.toString()} Товары: ${orderList}`;
          const operator_url = `https://t.me/${manager}?text=${messageTemplate}`;
          const text = `Так как вы не поделились контактом, отправьте менеджеру сформированное сообщение, чтобы с вами смогли связаться.`;
          const params = {
            message: text,
            buttons: [{ id: '13', text: "Отправить" }],
          };
          webApp.showPopup(params, async (action: string) => {
            if (action === params.buttons[0].id) {
              webApp.openTelegramLink(operator_url);
            }
          });
        }*/
      } catch (error) {
        runInAction(() => {
          this.loading = false;
          this.error = "Ошибка при создании заказа";
        });
      }
    };

    // Запрашиваем подтверждение у пользователя
    if(process.env.NODE_ENV !== "development"){
      webApp.showPopup(
        {
          message: 'Вы подтверждаете оформление заказа?',
          buttons: [
            { id: '2', type: 'cancel', text: 'Нет' },
            { id: '1', type: 'ok', text: 'Да' },
          ],
        },
        (buttonId: string) => {
          if (buttonId === '1') {
            confirmOrder();
          }
        }
      );
    }else{
      confirmOrder();
    }
    
  }
}
