// src/screens/CatalogScreen/CatalogScreen.tsx
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './CatalogScreen.module.scss';
import Catalog from '../../components/Catalog/Catalog';
import { useStores } from '../../stores/RootStoreContext';
import Filters from '../../components/Filters/Filters';
import Load from '../../components/Load/Load';

const CatalogScreen: React.FC = observer(() => {
     const { catalogStore } = useStores();
     
      useEffect(() => {
        if (!catalogStore.goods.length) {
        const initApp = async () => {
          try {
            await catalogStore.fetchCatalogData();
          } catch (error) {
            console.error("Ошибка при инициализации приложения", error);
          }
        };
        initApp();
    }
        // Если требуется вызвать статистику, можно добавить вызов appClick через соответствующий store.
      }, []);
    return <div className={styles.container}>
       {catalogStore.loading && <Load message='Загружаем'/>}
        {catalogStore.isFiltersVisible && <Filters />}
        <Catalog />
    </div>;
});

export default CatalogScreen;
