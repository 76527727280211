// src/screens/CatalogScreen/CatalogScreen.tsx
import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Card from '../Card/Card';
import styles from './Catalog.module.scss';
import { useStores } from '../../stores/RootStoreContext';

interface ScrollToTopButtonProps {
    visible: boolean;
    onClick: () => void;
}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ visible, onClick }) => {
    return (
        <div
            className={`${styles.scrollToTopButton} ${visible ? styles.show : styles.hide}`}
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 24 24"
                fill="none"
                stroke="var(--tg-theme-button-color)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <circle cx="12" cy="12" r="10" />
                <path d="M12 8v8M8 12l4-4 4 4" />
            </svg>
        </div>
    );
};

interface CatalogProps {
    // Если родительский компонент хочет передать ref для контейнера,
    // его можно указать через этот пропс. Если нет – используется локальный ref.
    appRef?: React.RefObject<HTMLDivElement>;
}

const Catalog: React.FC<CatalogProps> = observer(({ appRef }) => {
    const { catalogStore } = useStores();
    // Если ref не передан через пропсы, создаём локальный
    const localAppRef = useRef<HTMLDivElement>(null);
    const containerRef = appRef || localAppRef;

    
    // При монтировании навешиваем обработчик скролла
    useEffect(() => {
        const ref = containerRef.current;
        if (ref) {
            ref.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (ref) {
                ref.removeEventListener('scroll', handleScroll);
            }
        };
        // catalogStore здесь используется внутри handleScroll – поэтому он входит в зависимости
    }, [containerRef, catalogStore]);
    
    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
            catalogStore.setScrollTop(scrollTop);
            
            // Проверяем, что не достигнут конец списка товаров
            if (scrollHeight - scrollTop <= clientHeight + 50 && 
                catalogStore.endIndex < catalogStore.goods.length) {
                catalogStore.setEndIndex(catalogStore.endIndex + 12);
            }
            
            catalogStore.setShowScrollButton(scrollTop > 200);
        }
    };

    const scrollToTop = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
                catalogStore.setShowScrollButton(false);
                catalogStore.setEndIndex(12);
            }, 1000);
        }
    };

    return (
        <div ref={containerRef} className={styles.catalogContainer}>
            {catalogStore.goods.length === 0 ? (
                <p></p>
            ) : (
                catalogStore.visibleCards.map((card) => (
                    // Передаём endIndex и containerRef, если компонент Card их использует для сохранения позиции
                    <Card key={card.id}
                        id={card.id}
                        name={card.name}
                        photoUrl={card.photoUrl ?? undefined}
                        price={card.price}
                        endIndex={catalogStore.endIndex}
                        appRef={containerRef}
                    />
                ))
            )}
            <ScrollToTopButton visible={catalogStore.showScrollButton} onClick={scrollToTop} />
        </div>
    );
});

export default Catalog;
