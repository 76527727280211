import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Order } from '../../stores/abstractions/Order';
import { useStores } from '../../stores/RootStoreContext';
import styles from './OrderItem.module.scss';
import ImageCard from '../ImageCard/ImageCard';

interface OrderItemProps {
    order: Order;
}

const OrderItem: FC<OrderItemProps> = observer(({ order }) => {
    const { orderStore } = useStores();

    const handleCancelOrder = () => {
        orderStore.cancelOrder(order.clientOrderId);
    };

    return (
        <div className={styles.orderItem}>
            <div className={styles.header}>
                <div className={styles.title}>Заказ #{order.clientOrderId.slice(0, 8)}</div>
                <div className={styles.date}>{new Date(order.createdAt).toDateString()}</div>
            </div>
            <div className={styles.status}>
                <span>{order.isCompleted ? "Завершен" : "В обработке"}</span>
                <span>{order.paymentStatus}</span>
            </div>
            <div className={styles.items}>
                {order.orderPositions.map(position => (
                    <div key={position.id} className={styles.orderPosition}>
                        <div className={styles.imageBlock}>
                            <ImageCard photo={position.photoUrl} />
                        </div>
                        <div className={styles.positionHeader}>
                            <div className={styles.name}>{position.goodName}</div>
                            <div className={styles.amountPriceBlock}>
                                <div className={styles.amount}>{position.amount} шт</div>
                                <div className={styles.price}>{position.price} ₽</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.totalSum}></div>
            {!order.isCompleted && (
                <button className={styles.cancelButton} onClick={handleCancelOrder}>
                    Отменить заказ
                </button>
            )}
        </div>
    );
});

export default OrderItem;
