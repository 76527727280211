// src/components/ErrorMessage/ErrorMessage.tsx
import React, { FC, useEffect } from 'react';
import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  message: string;
  duration?: number; // время в мс, по умолчанию 3000
  onClear?: () => void; // callback, который очистит флаг ошибки в store
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message, duration = 3000, onClear }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onClear) {
        onClear();
      }
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onClear]);

  return (
    <div className={styles.overlay}>
       <div className={styles.container}>
        <div className={styles.errorMessage}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
