// src/screens/OrdersScreen/OrdersScreen.tsx
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './OrdersScreen.module.scss';
import { useStores } from '../../stores/RootStoreContext';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import Load from '../../components/Load/Load';
import OrderItem from '../../components/OrderItem/OrderItem';

const OrdersScreen: React.FC = observer(() => {
    const {orderStore} = useStores();
    useEffect(()=> {
        const init = async () => {
            await orderStore.fetchOrders();
        } 
        init();
    }, []);
    return (
    <div className={styles.container}>
        <div className={styles.title}>Мои заказы</div>
            {orderStore.loading && <Load message="Загружаем заказы..." />}
            {orderStore.error && <ErrorMessage message={orderStore.error} />}
            {!orderStore.loading && (
               <div className={styles.ordersList}>
               {orderStore.orders?.map(order => (
                   <OrderItem key={order.id} order={order} />
               ))}
           </div>
            )}
           
    </div>);
});

export default OrdersScreen;
