import { makeAutoObservable, runInAction } from "mobx";
import $api from "../http/api";
import { RootStore } from "./RootStore";

export class AppStore {
    loading: boolean = false;
    error: string | null = null;
    isMenuVisible: boolean = true;
    backButtonVisible: boolean = false;
    isInWebApp: boolean = false;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.initialize();
    }

    async handleStartParam(): Promise<void> {
        const startParam = window.Telegram?.WebApp?.initDataUnsafe?.start_param;
        console.log(startParam)
        if (!startParam) return;

        if (startParam.startsWith('good_')) {
            const link = startParam;
            await this.rootStore.goodStore.getGoodDataByLink(link);
        }

        // В дальнейшем можно добавлять другие префиксы:
        // else if (startParam.startsWith('ref_')) { ... }
    }

    initialize() {
        console.debug('Initializing WebAppStore...');
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.ready();
            window.Telegram.WebApp.expand();
            this.isInWebApp = true;
            console.debug('Running in Telegram miniApp');
            this.updateBackButtonVisibility();

            window.Telegram.WebApp?.BackButton.onClick(() => {
                this.handleBackButton();
            });
        }
    }
    async appClick(scope: string): Promise<void> {
        try {
            const response = await $api.post("/appClick", { addScope: true });
            if (response.status === 200) {
                runInAction(() => {
                    this.loading = false;
                });
            } else {
                runInAction(() => {
                    this.error = "Error processing app click";
                });
            }
        } catch (error) {
            runInAction(() => {
                this.error = "Error processing app click";
            });
        }
    }
    setMenuVisibility(isVisible: boolean) {
        this.isMenuVisible = isVisible;
    }
    updateBackButtonVisibility() {
        if (window.location.pathname !== '/') {
            this.showBackButton();
        } else {
            this.hideBackButton();
        }
    }

    showBackButton() {
        this.backButtonVisible = true;
        window.Telegram.WebApp?.BackButton.show();
    }

    hideBackButton() {
        this.backButtonVisible = false;
        window.Telegram.WebApp?.BackButton.hide();
    }

    handleBackButton() {
        if (this.backButtonVisible) {
            window.history.back();
            this.updateBackButtonVisibility();
        }
    }
}